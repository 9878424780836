<template>
  <validation-observer ref="editleader">
    <b-card title="Edit Data Leader">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="namaLengkap"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Lengkap"
              rules="required"
            >
              <b-form-input
                id="namaLengkap"
                v-model="userData.nama"
                :state="errors.length > 0 ? false:null"
                placeholder="Nama Lengkap"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nomor HP"
            label-for="nomorHP"
          >
            <validation-provider
              #default="{ errors }"
              name="Nomor HP"
              rules="required"
            >
              <b-input-group>
                <b-form-input
                  id="nomorHP"
                  v-model="userData.no_hp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nomor HP"
                  type="number"
                />
              </b-input-group>
              <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
              <br>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="userData.role === 'leader'"
          md="12"
        >
          <b-row class="align-items-end">
            <b-col md="3">
              <b-form-group
                label="Layanan"
              >
                <validation-provider
                  ref="layanan"
                  #default="{ errors }"
                  name="Layanan"
                >
                  <v-select
                    v-model="layanan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="vertikaloptions"
                    placeholder="Layanan"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Provinsi"
              >
                <validation-provider
                  ref="provinsi"
                  #default="{ errors }"
                  name="Provinsi"
                >
                  <v-select
                    v-model="provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="provinsiOptions"
                    placeholder="Provinsi"
                    :clearable="false"
                    @option:selected="getKota()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Kota"
              >
                <validation-provider
                  ref="kota"
                  #default="{ errors }"
                  name="Kota"
                >
                  <v-select
                    ref="kotaSelect"
                    v-model="kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="kotaOptions"
                    placeholder="Kota"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                variant="primary"
                block
                class="mb-1"
                @click.prevent="addLeaderArea"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="area.length"
          md="12"
          class="mb-1"
        >
          <b-badge
            v-for="item in area"
            :key="item.value"
            variant="light-primary"
            class="mr-50"
            @click="deleteArea(item)"
          >
            <h6 class="text-primary">
              {{ item.text }}
              <feather-icon
                icon="XIcon"
                size="32"
              />
            </h6>
          </b-badge>
        </b-col>
        <b-col
          md="6"
          offset="6"
        >
          <b-button
            variant="primary"
            class="btn-block"
            @click.prevent="editLeader"
          >
            <span v-if="!loading">Submit</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import regionJson from '@/db/region.json'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BBadge,
    ToastificationContent,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['id'],
  data() {
    return {
      vertikaloptions: [
        { value: 'vl01', text: 'Akupunktur' },
        { value: 'vl02', text: 'Auto' },
        { value: 'vl03', text: 'Barber' },
        { value: 'vl04', text: 'Bekam' },
        { value: 'vl05', text: 'Beauty' },
        { value: 'vl06', text: 'Cleaning' },
        { value: 'vl07', text: 'Massage' },
        { value: 'vl08', text: 'Cuci AC' },
      ],
      region: regionJson,
      provinsiOptions: [],
      kotaOptions: [],
      userData: {},
      layanan: '',
      provinsi: '',
      kota: '',
      role: '',
      fotoUser: undefined,
      loading: false,
      area: [],
      required,
    }
  },
  created() {
    db.collection('region').get().then(querySnapshot => {
      const arr = []
      querySnapshot.forEach(doc => {
        arr.push({
          id: doc.id,
          text: doc.data().nama_provinsi,
        })
      })
      this.provinsiOptions = [...new Set(arr)]
    })

    db.collection('mecarehub').doc(this.id).get().then(querySnapshot => {
      this.userData = querySnapshot.data()
      if (this.userData.role === 'leader') {
        this.userData.access.mitra.area.forEach(el => {
          const vertikal = el.split('-')[0]
          const kota = el.split('-')[1]
          this.area.push({
            text: `${this.vertikalLeader(vertikal)} - ${this.kotaLeader(kota)}`,
            value: el,
          })
        })
      }
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getKota() {
      this.$refs.kotaSelect.clearSelection()
      db.collection('region').doc(this.provinsi.id).collection('kota').get()
        .then(querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            arr.push({
              id: doc.id,
              text: doc.data().nama_kota,
            })
          })
          this.kotaOptions = [...new Set(arr)]
        })
    },
    async editLeader() {
      /* eslint-disable no-unused-vars */
      this.$refs.editleader.validate().then(success => {
        if (success) {
          this.loading = true

          if (this.userData.no_hp.charAt(0) === 0) {
            this.userData.no_hp = this.userData.no_hp.substring(1)
          }

          let areaLeader = [`${this.layanan.value}-${this.kota.id}`]
          if (this.area.length) {
            areaLeader = []
            this.area.forEach(el => {
              areaLeader.push(el.value)
            })
          }

          db.collection('mecarehub').doc(this.id).set({
            nama: this.userData.nama,
            no_hp: this.userData.no_hp,
            access: {
              mitra: {
                add: this.userData.access.mitra.add,
                read: this.userData.access.mitra.read,
                area: areaLeader,
                leader_id: this.id,
              },
            },
          }, { merge: true }).then(() => {
            this.loading = false
            this.$router.push({ name: 'leader' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil Diubah',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    kotaLeader(area) {
      /* eslint-disable arrow-body-style */
      const fromjson = regionJson.find(item => {
        return item.id === area
      })

      const region = fromjson.name.split(' ')

      region.shift()

      const kota = region.join(' ')

      return kota.toLowerCase()
    },
    vertikalLeader(vl) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }

      return vertikalVariant[vl]
    },
    addLeaderArea() {
      this.area.push({
        value: `${this.layanan.value}-${this.kota.id}`,
        text: `${this.layanan.text} ${this.kota.text}`,
      })
      this.$refs.layanan.reset()
      this.$refs.kota.reset()
      this.$refs.provinsi.reset()
    },
    deleteArea(item) {
      const index = this.area.indexOf(item)
      if (index > -1) {
        this.area.splice(index, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.Mitra-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
